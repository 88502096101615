<template>
  <DetailTemplate
    :customClass="'enquiry-detail detail-page'"
    v-if="getPermission('enquiry:view')"
  >
    <template v-slot:sidebar-detail>
      <v-card class="h-100" elevation="0">
        <template v-if="dbEnquiry.barcode">
          <div class="d-flex custom-border-righ">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  label
                  outlined
                  color="blue darken-4"
                  class="font-weight-600"
                  >{{ dbEnquiry.barcode }}</v-chip
                >
              </template>
              <span>Enquiry #</span>
            </v-tooltip>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  label
                  :color="dbEnquiry?.status_relation?.color"
                  :text-color="dbEnquiry?.status_relation?.textcolor"
                  class="ml-2 text-uppercase font-weight-600"
                  >{{ dbEnquiry?.status_relation?.text }}</v-chip
                >
              </template>
              <span>Status</span>
            </v-tooltip>
            <v-chip
              v-if="dbEnquiry.onsite_assessment"
              label
              color="orange"
              outlined
              text-color=""
              class="ml-2 text-uppercase font-weight-600"
              >SITE ASSESSMENT</v-chip
            >
            <!--  <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  label
                  :color="dbEnquiry?.priority_relation?.color"
                  :text-color="dbEnquiry?.priority_relation?.textcolor"
                  class="ml-2 text-uppercase font-weight-600"
                  >{{ dbEnquiry?.priority_relation?.text }}</v-chip
                >
              </template>
              <span>Priority</span>
            </v-tooltip>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  label
                  :color="dbEnquiry?.impact_relation?.color"
                  :text-color="dbEnquiry?.impact_relation?.textcolor"
                  class="mx-2 text-uppercase font-weight-600"
                  >{{ dbEnquiry?.impact_relation?.text }}</v-chip
                >
              </template>
              <span>Impact</span>
            </v-tooltip> -->
          </div>
          <div class="d-flex custom-border-left">
            <v-tooltip
              v-if="dbEnquiry?.quotation_convt_date"
              top
              content-class="custom-top-tooltip"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  label
                  color="teal lighten-1 white--text"
                  class="ml-2 text-uppercase font-weight-600"
                  >Quotation</v-chip
                >
              </template>
              <span>Quotation Converted</span>
            </v-tooltip>
            <v-tooltip
              v-if="dbEnquiry?.invoice_convt_date"
              top
              content-class="custom-top-tooltip"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  label
                  color="lime darken-3 white--text"
                  class="ml-2 text-uppercase font-weight-600"
                  >Invoice</v-chip
                >
              </template>
              <span>Invoice Converted</span>
            </v-tooltip>
            <v-tooltip
              v-if="dbEnquiry?.visit_convt_date"
              top
              content-class="custom-top-tooltip"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                  label
                  color="brown darken-2 white--text"
                  class="ml-2 text-uppercase font-weight-600"
                  >Visit</v-chip
                >
              </template>
              <span>Visit Converted</span>
            </v-tooltip>
          </div>
          <div class="gray-background mt-4">
            <table width="100%">
              <tr>
                <td colspan="2">
                  <span
                    class="text-capitalize cursor-pointer font-size-20 color-custom-blue font-weight-700"
                    >{{ dbEnquiry?.customer_relation?.display_name }}</span
                  >
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Priority
                </td>
                <td class="py-1">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        label
                        outlined
                        :color="dbEnquiry?.priority_relation?.color"
                        small
                        class="text-uppercase font-weight-600"
                        >{{ dbEnquiry?.priority_relation?.text }}</v-chip
                      >
                    </template>
                    <span>Priority</span>
                  </v-tooltip>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Impact
                </td>
                <td class="py-1">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        label
                        small
                        outlined
                        :color="dbEnquiry?.impact_relation?.color"
                        class="text-uppercase font-weight-600"
                        >{{ dbEnquiry?.impact_relation?.text }}</v-chip
                      >
                    </template>
                    <span>Impact</span>
                  </v-tooltip>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Reference #
                </td>
                <td class="py-1">
                  <show-value
                    :object="dbEnquiry"
                    object-key="reference"
                    label="Reference #"
                  />
                </td>
              </tr>
              <!--  <tr>
                <td class="font-weight-600" width="150">Client Equipment </td>
                <td class="font-weight-600">
                  <show-value
                    :object="dbEnquiry"
                    object-key="client_equipment"
                    label="Client Equipment #"
                  />
                </td>
              </tr> -->
              <!--   <tr>
                <td class="font-weight-600" width="20%">
                  Door Location/Serial No.
                </td>
                <td class="font-weight-600">
                  <show-value
                    :object="dbEnquiry"
                    object-key="door_location"
                    label="Door Location/Serial No."
                  />
                </td>
              </tr> -->
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Call No.
                </td>
                <td class="py-1">
                  <show-value
                    :object="dbEnquiry"
                    object-key="call_no"
                    label="Call No."
                  />
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Call Date
                </td>
                <td class="py-1">
                  <template v-if="dbEnquiry && dbEnquiry?.call_date">
                    {{ formatDate(dbEnquiry?.call_date) }}
                  </template>
                  <em v-else class="text-muted"> no call date </em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Due Date
                </td>
                <td class="py-1">
                  <template v-if="dbEnquiry && dbEnquiry?.due_date">
                    <span
                      :class="totalDays(dbEnquiry) == 0 ? 'red--text' : ''"
                      >{{ formatDate(dbEnquiry?.due_date) }}</span
                    >
                  </template>
                  <em v-else class="text-muted"> no due date </em>

                  <!--  <span class="red--text" v-if="totalDays(dbEnquiry) > 0"
                    >({{ totalDays(dbEnquiry) }} Days)</span
                  > -->
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Remaining
                </td>
                <td class="py-1">
                  <span class="red--text"
                    >{{ totalDays(dbEnquiry) }}(Days)</span
                  >
                  <!--  <span
                    :class="
                      totalDays(dbEnquiry) && totalDays(dbEnquiry) == 0
                        ? 'red--text'
                        : ''
                    "
                    >{{ formatDate(dbEnquiry?.due_date) }}</span
                  >
                  <span class="red--text" v-if="totalDays(dbEnquiry) > 0"
                    >({{ totalDays(dbEnquiry) }} Days)</span
                  > -->
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Assign To
                </td>
                <td class="py-1">
                  <v-chip
                    v-if="
                      dbEnquiry &&
                      dbEnquiry.assign_relation &&
                      dbEnquiry.assign_relation.display_name
                    "
                    class="mb-1 mr-1"
                  >
                    <v-avatar left>
                      <img
                        v-if="
                          dbEnquiry.assign_relation &&
                          dbEnquiry.assign_relation.custome_profile_logo &&
                          dbEnquiry.assign_relation.custome_profile_logo.file
                        "
                        :src="
                          dbEnquiry.assign_relation.custome_profile_logo.file
                            .url
                        "
                      />
                      <img
                        v-else
                        src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                      />
                    </v-avatar>
                    <span>{{ dbEnquiry.assign_relation.display_name }}</span>
                  </v-chip>
                  <em v-else class="text-muted">no Assign to</em>
                </td>
              </tr>
            </table>
          </div>
          <div class="mt-5">
            <table width="100%">
              <tr>
                <th
                  colspan="2"
                  class="grey lighten-4 font-size-16 text-uppercase color-custom-blue py-1"
                >
                  SCHEDULE AVAILABILITY
                </th>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  First Availability
                </td>
                <td class="py-1">
                  <template v-if="dbEnquiry && dbEnquiry?.first_available_date">
                    {{ formatDate(dbEnquiry?.first_available_date) }}
                  </template>
                  <em v-else class="text-muted"> no First Availability </em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Time
                </td>
                <td class="py-1">
                  <template v-if="dbEnquiry && dbEnquiry?.first_available_date">
                    {{ formatDateStartTime(dbEnquiry?.first_available_date) }}
                  </template>
                  <em v-else class="text-muted"> no time </em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Second Availability
                </td>
                <td class="py-1">
                  <template
                    v-if="dbEnquiry && dbEnquiry?.second_available_date"
                  >
                    {{ formatDate(dbEnquiry?.second_available_date) }}
                  </template>
                  <em v-else class="text-muted"> no Second Availability </em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="150">
                  Time
                </td>
                <td class="py-1">
                  <template
                    v-if="dbEnquiry && dbEnquiry?.second_available_date"
                  >
                    {{ formatDateStartTime(dbEnquiry?.second_available_date) }}
                  </template>
                  <em v-else class="text-muted"> no time </em>
                </td>
              </tr>
              <tr>
                <th
                  colspan="2"
                  class="grey lighten-4 font-size-16 text-uppercase color-custom-blue py-1 text-capitalize"
                >
                  Tags
                </th>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-1 py-2" colspan="2">
                  <div class="d-flex flex-wrap">
                    <template
                      v-if="
                        dbEnquiry?.tags &&
                        dbEnquiry?.tags?.length &&
                        dbEnquiry?.tags?.length > 0
                      "
                    >
                      <v-chip
                        small
                        v-for="(row, index) in dbEnquiry.tags"
                        :key="`enquiry-tag-${index}`"
                        class="px-2 custom-border-radius-10 mr-2 mb-2"
                        :style="`color: ${row.color}; border: solid 1px ${row.color}`"
                        >{{ row.text }}</v-chip
                      >
                    </template>
                    <em v-else class="text-muted"> no tags available </em>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </template>
      </v-card>
    </template>
    <template v-slot:header-title>
      <h1
        class="form-title custom-nowrap-ellipsis text-capitalize mr-2 font-weight-700"
      >
        {{ dbEnquiry?.title }}
      </h1>
    </template>
    <v-spacer></v-spacer>
    <template v-slot:header-action>
      <div class="d-flex align-center justify-end pt-0">
        <v-btn
          v-if="dbEnquiry?.status != 6"
          v-on:click="(confirmDialog = true), (confirmType = 'mark_as_closed')"
          depressed
          color="red white--text"
          class="custom-bold-button mx-2"
          ><v-icon small left>mdi-close</v-icon> Mark As Close</v-btn
        >
        <v-btn
          v-if="dbEnquiry?.status == 1"
          v-on:click="updateMoreAction('edit')"
          depressed
          color="cyan white--text"
          class="custom-bold-button mx-2"
          ><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
        >
        <template
          v-if="dbEnquiry?.more_actions && dbEnquiry?.more_actions.length"
        >
          <template v-if="dbEnquiry?.more_actions.length">
            <v-menu
              v-if="dbEnquiry?.more_actions && dbEnquiry?.more_actions.length"
              transition="slide-y-transition"
              bottom
              content-class="custom-menu-list"
              offset-y
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  color="cyan white--text"
                  class="custom-bold-button mx-2"
                  >More... <v-icon small right>mdi-chevron-down</v-icon></v-btn
                >
              </template>
              <v-list>
                <template v-for="(more, index) in dbEnquiry?.more_actions">
                  <v-list-item
                    link
                    v-on:click="updateMoreAction(more.action)"
                    :key="index"
                    :disabled="more.disabled"
                  >
                    <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                      <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="font-weight-500 font-size-14">{{
                      more.title
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </template>
          <!--  <template v-else>
            <v-btn v-for="(more, index) in dbEnquiry?.more_actions" v-on:click="updateMoreAction(more.action)"
              :key="index" :disabled="more.disabled" depressed color="cyan white--text"
              class="custom-bold-button mx-2"><v-icon small left>{{ more.icon }}</v-icon>
              {{ more.title }}</v-btn>
          </template> -->
        </template>
        <v-btn v-on:click="goBack()" depressed class="custom-bold-button"
          ><v-icon small left>mdi-chevron-left</v-icon>Back</v-btn
        >
      </div>
    </template>
    <template v-slot:body>
      <v-row class="mx-0" v-if="pageLoaded">
        <v-col md="12" class="pt-0">
          <v-tabs
            active-class="custom-tab-active"
            v-model="enquiryTab"
            background-color="transparent"
            color="cyan"
            class="custom-tab-transparent tab-sticky"
          >
            <v-tab class="font-size-16 font-weight-600 px-8" href="#overview">
              <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                <!--begin::Svg Icon-->
                <inline-svg :src="$assetURL('media/custom-svg/overview.svg')" />
                <!--end::Svg Icon-->
              </span>
              Overview
            </v-tab>
            <v-tab class="font-size-16 font-weight-600 px-8" href="#line-item">
              <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                <!--begin::Svg Icon-->
                <inline-svg
                  :src="$assetURL('media/custom-svg/line-item.svg')"
                />
                <!--end::Svg Icon-->
              </span>
              Line Items
            </v-tab>
            <v-tab class="font-size-16 font-weight-600 px-8" href="#quotation">
              <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                <!--begin::Svg Icon-->
                <inline-svg
                  :src="$assetURL('media/custom-svg/quotation.svg')"
                />
                <!--end::Svg Icon-->
              </span>
              Quotation
            </v-tab>
            <v-tab class="font-size-16 font-weight-600 px-8" href="#invoice">
              <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                <!--begin::Svg Icon-->
                <inline-svg :src="$assetURL('media/custom-svg/invoice.svg')" />
                <!--end::Svg Icon-->
              </span>
              Invoice
            </v-tab>
            <v-tab class="font-size-16 font-weight-600 px-8" href="#visit">
              <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                <!--begin::Svg Icon-->
                <inline-svg :src="$assetURL('media/custom-svg/visit.svg')" />
                <!--end::Svg Icon-->
              </span>
              Visits
            </v-tab>
            <v-tab class="font-size-16 font-weight-600 px-8" href="#history">
              <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                <!--begin::Svg Icon-->
                <inline-svg :src="$assetURL('media/custom-svg/history.svg')" />
                <!--end::Svg Icon-->
              </span>
              History
            </v-tab>
            <v-tab class="font-size-16 font-weight-600 px-8" href="#attachment">
              <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                <!--begin::Svg Icon-->
                <inline-svg
                  :src="$assetURL('media/custom-svg/attachment.svg')"
                />
                <!--end::Svg Icon-->
              </span>
              Attachments
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="enquiryTab" class="custom-border-top">
            <v-tab-item value="overview">
              <v-expansion-panels mandatory accordion>
                <v-expansion-panel>
                  <!--  <v-expansion-panel-header class="grey lighten-4">
                    <v-card-title class="headline">
                      <h3
                        class="font-weight-600 custom-headline color-custom-blue font-size-14"
                      >
                        PROBLEM & ATTACHMENTS
                      </h3>
                    </v-card-title>
                  </v-expansion-panel-header> -->
                  <v-expansion-panel-content
                    class="pt-4 mt-3 custom-grey-border"
                  >
                    <v-row>
                      <v-col md="12">
                        <label class="font-size-16 font-weight-600">
                          <h3
                            class="font-weight-600 custom-headline color-custom-blue font-size-20"
                          >
                            Problem
                          </h3>
                        </label>
                        <div class="w-100 custom-grey-border p-4">
                          {{ dbEnquiry?.problem }}
                        </div>
                      </v-col>
                      <v-col md="12">
                        <label class="font-size-16 font-weight-600">
                          <h3
                            class="font-weight-600 custom-headline color-custom-blue font-size-20"
                          >
                            Notes
                          </h3>
                        </label>
                        <div class="w-100 custom-grey-border p-4">
                          {{ dbEnquiry?.technician_sketch }}
                        </div>
                      </v-col>
                      <v-col
                        md="12"
                        class="custom-border-top mt-4"
                        v-if="false"
                      >
                        <v-row>
                          <v-col md="6">
                            <label class="font-size-16 font-weight-600">
                              <h3
                                class="font-weight-600 custom-headline color-custom-blue font-size-14"
                              >
                                Attachments ({{
                                  dbEnquiry?.attachments?.length
                                }})
                              </h3>
                            </label>
                            <table width="100%" class="custom-border-top-1px">
                              <tbody>
                                <tr
                                  v-for="(row, index) in dbEnquiry.attachments"
                                  :key="`view-attach-${index}`"
                                >
                                  <td
                                    valign="middle"
                                    width="80%"
                                    class="pr-2 py-2"
                                  >
                                    {{ row.name }}
                                  </td>
                                  <td
                                    valign="middle"
                                    width="20%"
                                    align="right"
                                    class="pl-2 py-2"
                                    v-on:click="downloadFile(row.file.url)"
                                  >
                                    <v-icon color="cyan" class="mr-2"
                                      >mdi-download</v-icon
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row>
                <v-col md="12">
                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius mb-4 h-100"
                  >
                    <v-card-title class="headline grey lighten-4 py-1">
                      <span
                        class="font-weight-700 custom-headline color-custom-blue font-size-16 text-uppercase"
                      >
                        Address</span
                      >
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <!-- <span
                      v-on:click="routeToCustomer()"
                      class="cursor-pointer color-custom-blue font-weight-700 font-size-19"
                      >{{ dbEnquiry?.customer_relation?.display_name }}</span
                    > -->
                      <v-row>
                        <v-col md="6" class="py-0">
                          <v-row>
                            <v-col md="6" class="py-0">
                              <span
                                class="font-weight-700 custom-headline color-custom-blue font-size-16"
                              >
                                Billing Location</span
                              >
                              <template v-if="dbEnquiry?.billing_relation?.id">
                                <span>
                                  <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                                >
                                <p
                                  class="mr-2"
                                  v-if="dbEnquiry?.billing_relation"
                                >
                                  <span
                                    v-if="
                                      dbEnquiry.billing_relation?.property_name
                                    "
                                    >{{
                                      dbEnquiry.billing_relation?.property_name
                                    }}<br
                                  /></span>
                                  {{ dbEnquiry?.billing_relation?.street_1 }},
                                  <br
                                    v-if="
                                      dbEnquiry?.billing_relation?.street_2 ||
                                      dbEnquiry?.billing_relation?.unit_no
                                    "
                                  />
                                  <template
                                    v-if="dbEnquiry?.billing_relation?.street_2"
                                  >
                                    {{ dbEnquiry?.billing_relation?.street_2 }},
                                  </template>
                                  <template
                                    v-if="dbEnquiry?.billing_relation?.unit_no"
                                  >
                                    {{ dbEnquiry?.billing_relation?.unit_no }},
                                  </template>
                                  <br />
                                  {{ dbEnquiry?.billing_relation?.country }}
                                  <template
                                    v-if="
                                      dbEnquiry?.billing_relation &&
                                      dbEnquiry?.billing_relation?.zip_code !=
                                        '000000'
                                    "
                                  >
                                    ,
                                    {{ dbEnquiry?.billing_relation?.zip_code }}
                                  </template>
                                </p>
                              </template>

                              <span
                                class="font-weight-700 custom-headline color-custom-blue font-size-16"
                              >
                                Contact Details</span
                              >
                              <template
                                v-if="dbEnquiry?.billing_person_relation?.id"
                              >
                                <p class="mb-0">
                                  {{
                                    dbEnquiry?.billing_person_relation
                                      ?.display_name
                                  }}
                                </p>
                                <p class="mb-0">
                                  {{
                                    dbEnquiry?.billing_person_relation
                                      ?.primary_phone
                                  }}
                                </p>
                                <p class="mb-0 d-flex align-center">
                                  {{
                                    dbEnquiry?.billing_person_relation
                                      ?.primary_email
                                  }}
                                </p>
                              </template>
                              <!--  <div class="d-flex mt-3">
                                <label class="my-0 mr-2">Office No.</label>
                                <p class="mb-0">
                                  <show-value
                                    :object="dbEnquiry"
                                    object-key="customer_relation.company_number"
                                    label="Office No."
                                  />
                                </p>
                              </div>
                              <div class="d-flex mt-3">
                                <label class="my-0 mr-2">Fax No.</label>
                                <p class="mb-0">
                                  <show-value
                                    :object="dbEnquiry"
                                    object-key="customer_relation.company_fax"
                                    label="Fax No."
                                  />
                                </p>
                              </div> -->
                            </v-col>
                            <v-col md="6" class="py-0">
                              <span
                                class="font-weight-700 custom-headline color-custom-blue font-size-16"
                              >
                                Site Location</span
                              >
                              <template v-if="dbEnquiry?.property_relation?.id">
                                <span>
                                  <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                                >
                                <p
                                  class="mr-2"
                                  v-if="dbEnquiry?.property_relation"
                                >
                                  <span
                                    v-if="
                                      dbEnquiry.property_relation?.property_name
                                    "
                                    >{{
                                      dbEnquiry.property_relation
                                        ?.property_name
                                    }}<br
                                  /></span>
                                  {{ dbEnquiry?.property_relation?.street_1 }},
                                  <br
                                    v-if="
                                      dbEnquiry?.property_relation?.street_2 ||
                                      dbEnquiry?.property_relation?.unit_no
                                    "
                                  />
                                  <template
                                    v-if="
                                      dbEnquiry?.property_relation?.street_2
                                    "
                                  >
                                    {{
                                      dbEnquiry?.property_relation?.street_2
                                    }},
                                  </template>
                                  <template
                                    v-if="dbEnquiry?.property_relation?.unit_no"
                                  >
                                    {{ dbEnquiry?.property_relation?.unit_no }},
                                  </template>
                                  <br />
                                  {{ dbEnquiry?.property_relation?.country }}
                                  <template
                                    v-if="
                                      dbEnquiry?.property_relation &&
                                      dbEnquiry?.property_relation?.zip_code !=
                                        '000000'
                                    "
                                  >
                                    ,
                                    {{ dbEnquiry?.property_relation?.zip_code }}
                                  </template>
                                </p>
                              </template>
                              <span
                                class="font-weight-700 custom-headline color-custom-blue font-size-16"
                              >
                                Contact Details</span
                              >

                              <template
                                v-if="dbEnquiry?.property_person_relation?.id"
                              >
                                <p class="mb-0">
                                  {{
                                    dbEnquiry?.property_person_relation
                                      ?.display_name
                                  }}
                                </p>
                                <p class="mb-0">
                                  {{
                                    dbEnquiry?.property_person_relation
                                      ?.primary_phone
                                  }}
                                </p>
                                <p class="mb-0 d-flex align-center">
                                  {{
                                    dbEnquiry?.property_person_relation
                                      ?.primary_email
                                  }}
                                </p>
                              </template>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="line-item">
              <EnquiryLineItem
                :show-price="dbEnquiry.show_price"
                v-on:line-item="lengthCount"
                :discount-level="
                  dbEnquiry.discount_type == 2 ? 'line_item' : 'transaction'
                "
                :related-id="enquiryId"
                :related-type="6"
                is-enquiry
              />
              <div
                class="mt-4 pr-2 font-weight-600"
                v-if="itemCount && itemCount > 0"
              >
                <table width="100%">
                  <tr>
                    <td width="80%"></td>
                    <td width="10%">Sub Total</td>
                    <td width="10%" align="right">
                      {{ formatMoney(dbEnquiry.sub_total) }}
                    </td>
                  </tr>
                  <tr v-if="dbEnquiry.discount_type == 1">
                    <td></td>
                    <td>
                      Discount
                      <span v-if="dbEnquiry.discount_value_type == 1"
                        >({{ formatMoney(dbEnquiry.discount_value) }})</span
                      >
                      <span v-if="dbEnquiry.discount_value_type == 2"
                        >({{ formatNumber(dbEnquiry.discount_value) }}%)</span
                      >
                    </td>
                    <td align="right">
                      {{ formatMoney(dbEnquiry.discount_amount) }}
                    </td>
                  </tr>
                  <tr v-if="dbEnquiry.tax_applied">
                    <td></td>
                    <td>Tax [GST {{ dbEnquiry.tax_value }}%]</td>
                    <td align="right">
                      {{ formatMoney(dbEnquiry.tax_amount) }}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Adjustment</td>
                    <td align="right">
                      {{ formatMoney(dbEnquiry.adjustment) }}
                    </td>
                  </tr>
                  <tr class="font-size-19">
                    <td></td>
                    <td>Grand Total</td>
                    <td align="right">{{ formatMoney(dbEnquiry.total) }}</td>
                  </tr>
                </table>
              </div>
            </v-tab-item>
            <v-tab-item value="quotation">
              <internal-quotation type="enquiry" :detail="dbEnquiry" />
            </v-tab-item>
            <v-tab-item value="invoice">
              <internal-invoice :type-id="dbEnquiry?.id" type="enquiry" />
            </v-tab-item>
            <v-tab-item value="visit">
              <VisitInternalAll
                :detail="dbEnquiry"
                type="enquiry"
                :enquiry-id="dbEnquiry?.id"
              ></VisitInternalAll>
            </v-tab-item>
            <v-tab-item value="history">
              <internal-history-detail
                type="enquiry"
                :type_id="dbEnquiry?.id"
              />
            </v-tab-item>
            <v-tab-item value="attachment">
              <AdditionalDocuments
                :type-id="dbEnquiry.id"
                class="mx-4"
                type-text="Enguiry Attachment"
                :type="51"
                :extensive="false"
              ></AdditionalDocuments>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col md="12">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <p class="text-center font-weight-600 font-size-19">Please Wait...</p>
          <v-progress-linear rounded indeterminate height="8" color="orange" />
        </v-col>
      </v-row>
    </template>
    <template v-slot:footer>
      <confirm-dialog
        key="confirm-dialog-enquiry"
        :common-dialog="confirmDialog"
        :dialog-width="dialogWidth"
      >
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-container class="px-10" fluid>
            <p class="font-weight-600 font-size-19">
              Are you sure, you want to
              <span class="font-weight-700">{{ getConfirmTitle() }}</span
              >?
            </p>
            <v-layout
              class="my-4"
              v-if="
                confirmType == 'mark_as_approved' ||
                confirmType == 'mark_as_rejected' ||
                confirmType == 'mark_as_accepted'
              "
            >
              <v-flex>
                <label class="font-weight-500 font-size-16 required"
                  >Remark</label
                >
                <v-textarea
                  v-model.trim="actionData.remark"
                  auto-grow
                  dense
                  filled
                  color="cyan"
                  label="Remark"
                  solo
                  flat
                  row-height="25"
                ></v-textarea>
              </v-flex>
            </v-layout>
            <v-layout class="mt-4" v-if="confirmType == 'mark_as_accepted'">
              <v-flex md12>
                <label class="font-weight-500 font-size-16"
                  >Attachments (Max 5)</label
                >
                <table width="100%">
                  <tbody>
                    <tr
                      v-for="(row, index) in actionData.attachments"
                      :key="index"
                    >
                      <td width="50%" class="pl-0 pr-2 py-2">
                        <v-file-input
                          prepend-inner-icon="mdi-attachment mdi-rotate-90"
                          placeholder="Click here to select file"
                          label="Click here to select file"
                          prepend-icon=""
                          color="cyan"
                          solo
                          flat
                          v-model="row.accepted_file"
                          dense
                          v-on:change="updateFileName(index)"
                          hide-details
                        ></v-file-input>
                      </td>
                      <td width="50%" class="pr-0 pl-2 py-2">
                        <v-text-field
                          placeholder="File Name"
                          label="File Name"
                          dense
                          solo
                          flat
                          color="cyan"
                          v-model="row.file_name"
                          hide-details
                          class="has-delete-outer-icon"
                          append-outer-icon="mdi-delete"
                          v-on:click:append-outer="removeRow(index)"
                          :suffix="getFileExtension(row.file_type)"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr v-if="actionData.attachments.length < 5">
                      <td colspan="2" class="pl-0">
                        <v-btn
                          color="cyan white--text"
                          small
                          v-on:click="addAttachmentRow()"
                          class="custom-bold-button"
                          >Add More...</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-flex>
            </v-layout>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            depressed
            :disabled="confirmLoading"
            v-on:click="confirmDialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No! Close
          </v-btn>
          <v-btn
            depressed
            v-on:click="confirmSubmit()"
            :disabled="confirmLoading"
            :loading="confirmLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
      </confirm-dialog>
      <send-mail
        :mail-dialog="mailDialog"
        title="Send as Email"
        type="enquiry"
        v-on:close="mailDialog = false"
        :type-id="enquiryId"
        v-on:success="getEnquiry()"
      ></send-mail>
      <FullDialog
        v-if="enquiryDialog"
        :dialog="enquiryDialog"
        content-class="testdata"
      >
        <template v-slot:title
          >{{ dbEnquiry.title }}({{ dbEnquiry.barcode }}) Notes
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                label
                :color="dbEnquiry?.status_relation?.color"
                :text-color="dbEnquiry?.status_relation?.textcolor"
                class="ml-2 text-uppercase font-weight-600"
                >{{ dbEnquiry?.status_relation?.text }}</v-chip
              >
            </template>
            <span>Status</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn
            class="text-white"
            color="red darken-4"
            depressed
            tile
            v-on:click="closeDialog"
          >
            Close
          </v-btn>
        </template>
        <template v-slot:body>
          <ChatNote
            v-if="enquiryDialog"
            :relatedId="enquiryId"
            :relatedType="'enquiry'"
            :documentTypes="500"
            create-url="enquiry-notes"
            get-url="enquiry-notes"
          ></ChatNote>
        </template>
        <!-- <template v-slot:action>
				<v-btn class="text-white" color="red darken-4" depressed tile v-on:click="closeDialog">
					Close
				</v-btn>
			</template> -->
      </FullDialog>
      <Dialog :commonDialog="typeConfirmDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col lg="12">
                <RadioInputCustom
                  v-model="jobType"
                  row
                  color="blue darken-4"
                  :items="[
                    {
                      label: 'Adhoc Job',
                      value: 'one-off',
                    },
                    {
                      label: `Recurring Job`,
                      value: 'recurring',
                    },
                  ]"
                ></RadioInputCustom>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="pushToVisit()"
          >
            Continue
          </v-btn>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="typeConfirmDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
      <div class="floating-btn-wrapper">
        <div class="position-relative">
          <div
            class="call-btn-underlay"
            style="background-color: rgb(43, 149, 105)"
          ></div>
          <v-icon
            style="font-size: 50px !important"
            color="green"
            small
            v-on:click="enquiryDialog = true"
            >mdi-chat
          </v-icon>
        </div>
      </div>
    </template>
  </DetailTemplate>
</template>
<script>
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import JwtService from "@/core/services/jwt.service";
import { UPLOAD, PATCH, GET } from "@/core/services/store/request.module";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import Dialog from "@/view/pages/partials/Dialog";
import ObjectPath from "object-path";
import ShowValue from "@/view/components/ShowValue.vue";
import SendMail from "@/view/pages/partials/Send-Mail";
import InternalQuotation from "@/view/pages/partials/Detail/Quotation";
import InternalInvoice from "@/view/pages/partials/Internal-Invoice";

/* import InternalQuotation from "@/view/pages/partials/Detail/Quotation";
import InternalInvoice from "@/view/pages/partials/Internal-Invoice"; */
import EnquiryLineItem from "@/view/components/VisitLineItem.vue";
/* import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib"; */
import FullDialog from "@/view/components/FullDialog";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
import moment from "moment-timezone";
import ChatNote from "@/view/pages/partials/ChatNote";
import RadioInputCustom from "@/view/components/RadioInputCustom.vue";
import VisitInternalAll from "@/view/pages/partials/Detail/VisitInternalAll.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  data() {
    return {
      typeConfirmDialog: false,
      jobType: "one-off",

      formLoading: false,
      tab: null,
      enquiryTab: "line-item",
      dbEnquiry: {},
      enquiryId: 0,
      pageLoaded: false,
      itemCount: 0,
      mailDialog: false,
      confirmDialog: false,
      confirmLoading: false,
      enquiryDialog: false,
      confirmType: null,
      actionData: {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      },
      pageLoading: true,
    };
  },
  methods: {
    lengthCount(param) {
      this.itemCount = param;
    },
    downloadFile(url) {
      if (!url) {
        return false;
      }
      window.open(url, "_blank");
    },
    totalDays(date) {
      if (date.due_date) {
        let today = moment().format("YYYY-MM-DD");
        var a = moment(date.due_date);
        var b = moment(today); //date.start_date
        var final = a.diff(b, "days"); // =1
        if (final <= 0) {
          final = 0;
        }
        return final;
      } else {
        return 0;
      }
    },
    closeDialog() {
      this.enquiryDialog = false;
    },
    confirmSubmit() {
      switch (this.confirmType) {
        case "mark_as_closed":
          /*  this.updateStatus("mark_as_closed"); */
          this.mark_as_close(6);
          break;
        case "mark_as_cancelled":
          this.updateStatus("mark_as_cancelled");
          break;
        case "mark_as_pending":
          this.updateStatus("mark_as_pending");
          break;
        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;
        case "mark_as_accepted":
          this.updateStatus("mark_as_accepted");
          break;
        case "mark_as_approved":
          this.updateStatus("mark_as_approved");
          break;
      }
    },
    formatDateStartTime(row) {
      if (row) {
        return moment(row).format("hh:mm A");
      } else {
        return "-";
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.actionData.attachments.length; i++) {
          if (_this.actionData.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.actionData.attachments[i].accepted_file,
              _this.actionData.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    async updateStatus() {
      if (this.confirmLoading) {
        return false;
      }

      this.confirmLoading = true;

      this.actionData.status = this.confirmType;
      this.actionData.attachments = await this.uploadFiles();

      this.$store
        .dispatch(PATCH, {
          url: "enquiry/" + this.enquiryId + "/status",
          data: this.actionData,
        })
        .then(() => {
          this.getEnquiry();
          this.resetConfirm();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    resetConfirm() {
      this.confirmDialog = false;
      this.confirmType = null;
      this.actionData = {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      };
    },
    updateFileName(index) {
      const file = this.actionData.attachments[index].accepted_file;
      if (file) {
        this.actionData.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.actionData.attachments[index].file_type = file.name
          .split(".")
          .pop();
        this.addAttachmentRow();
      }
    },
    removeRow(index) {
      ObjectPath.del(this.actionData.attachments, index);
      this.$nextTick(() => {
        if (!this.actionData.attachments.length) {
          this.addAttachmentRow();
        }
      });
    },
    addAttachmentRow() {
      if (this.actionData.attachments.length < 5) {
        this.actionData.attachments.push({
          accepted_file: null,
          file_name: null,
          file_type: null,
        });
      }
    },
    getFileExtension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    getConfirmTitle() {
      if (this.confirmType == "mark_as_closed") {
        return "Mark as Closed";
      } else if (this.confirmType == "mark_as_cancelled") {
        return "Mark as Cancelled";
      } else if (this.confirmType == "mark_as_pending") {
        return "Mark as Pending";
      } else if (this.confirmType == "mark_as_rejected") {
        return "Mark as Rejected";
      } else if (this.confirmType == "mark_as_accepted") {
        return "Mark as Accepted";
      } else if (this.confirmType == "mark_as_approved") {
        return "Mark as Approved";
      }
    },
    editEnquiry() {
      this.$router.push(
        this.getDefaultRoute("enquiry.create", {
          query: { edit: this.dbEnquiry?.id },
        })
      );
    },
    updateMoreAction(action) {
      this.resetConfirm();
      switch (action) {
        case "edit":
          this.editEnquiry();
          break;
        case "convert_visit":
          this.routeToJob();
          break;
        case "convert_quotation":
          this.routeToQuotation();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "download_pdf":
          this.downloadPDF();
          break;
        /*   case "mark_as_close":
            this.mark_as_close(6);
            break;   */
      }
    },
    routeToCustomer() {
      this.$router.push(
        this.getDefaultRoute("customer.detail", {
          params: { customer: this.dbEnquiry?.customer },
        })
      );
    },
    routeToJob() {
      this.typeConfirmDialog = true;
    },
    pushToVisit() {
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            enquiry: this.dbEnquiry?.id,
            type: this.jobType,
          },
        })
      );
    },

    routeToQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { enquiry: this.dbEnquiry?.id },
        })
      );
    },
    routeToInvoice() {
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: { enquiry: this.dbEnquiry?.id },
        })
      );
    },
    routeToDuplicate() {
      this.$router.push(
        this.getDefaultRoute("enquiry.create", {
          query: { duplicate: this.dbEnquiry?.id },
        })
      );
    },
    downloadPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}enquiry/${this.enquiryId}/pdf?token=${token}&action=download`;
      window.open(url, "_blank");
    },
    printPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}enquiry/${this.enquiryId}/pdf?token=${token}&action=print`;
      window.open(url, "_blank");
    },
    formatDateTimeA(date) {
      var inputDate = moment.utc(date);
      var formattedDate = inputDate.format("DD/MM/YYYY hh:mm A");
      return formattedDate;
    },
    mark_as_close(status) {
      const _this = this;
      _this.formLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: `enquiry-status/${this.enquiryId}/${status}/`,
        })
        .then(() => {
          _this.confirmDialog = false;
          _this.getEnquiry();
          _this.formLoading = false;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getEnquiry() {
      this.$store
        .dispatch(GET, { url: "enquiry/" + this.enquiryId })
        .then(({ data }) => {
          this.dbEnquiry = data;
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Enquiry", route: "enquiry" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
          this.pageLoading = false;
        });
    },
  },
  created() {
    this.enquiryId = this.lodash.toSafeInteger(this.$route.params.id);
    this.enquiryTab = this.$route.query.tab ?? "overview";
  },
  mounted() {
    this.getEnquiry();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
  components: {
    "show-value": ShowValue,
    "internal-history-detail": InternalHistoryDetail,
    "internal-invoice": InternalInvoice,
    "internal-quotation": InternalQuotation,
    "confirm-dialog": Dialog,
    "send-mail": SendMail,
    DetailTemplate,
    EnquiryLineItem,
    ChatNote,
    RadioInputCustom,
    VisitInternalAll,
    Dialog,
    FullDialog,
    AdditionalDocuments,
  },
};
</script>
<style lang="scss" scoped>
.enquiry-detail-page {
  border-top: solid 8px #f7941e;

  label {
    padding-left: 5px;
    font-weight: 600;
  }

  p {
    padding-left: 5px;
  }
}

.sidebar--detail {
  width: 320px;
  max-width: 320px;
  height: calc(100vh - 95px);
  overflow: auto;
}

.main-section--detail {
  flex-grow: 1;
  width: calc(100% - 330px);
  max-width: calc(100% - 330px);

  &.full-width {
    width: 100%;
    max-width: 100%;
  }
}
</style>
